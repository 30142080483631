/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Baris Demirhan",
  title: "Merhaba, Ben Barış.",
  subTitle: emoji(
    "Full-Stack Web ve Mobil geliştirici olarak hizmet vermekteyim. İlk kez 2008 yılında 'Hello World!' dedim ve o zamandan beri İnternet okyanusu benim av alanım. 2011 yılından beri profesyonel olarak web uygulamaları, web siteleri ve mobil uygulamalar geliştiriyorum. Freelance olarak 150+ proje hayata geçirdim ve yenileri için sabırsızlanıyorum."
  ),
  resumeLink: "#",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/barisdemirhan",
  linkedin: "https://www.linkedin.com/in/baris-demirhan/",
  mail: "https://mailhide.io/e/En6xJ2A5",
  twitter: "https://twitter.com/baris_demirhan",
  spotify:
    "https://open.spotify.com/show/5uZiCeMk5447TmUz9h5gfh?si=bifp0JFUT5y9pfT2vkUDVQ&nd=1",
  display: true // Set true to display this section, defaults to false
};

export {illustration, greeting, socialMediaLinks};
